import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import "./header.scss"
import logo from "../images/logo.svg"
import MobileNav from "./mobileNav"
import { useTransition, animated } from "react-spring"
import { FaBars, FaTimesCircle } from "react-icons/fa"

const Header = ({ siteTitle }) => {
  const [show, setShow] = useState(false)
  const transitions = useTransition(show, null, {
    from: { position: "absolute", transform: "translate3d(0,-100%,0)" },
    enter: { transform: "translate3d(0,0,0)" },
    leave: { transform: "translate3d(0,-100%,0)" },
  })
  return (
    <>
      <header className="header">
        <div className="header-inner">
          <h1>
            <Link to="/">
              <img
                className="branding"
                src={logo}
                alt="cythia moody attorny at law logo"
              />
            </Link>
          </h1>
          <nav>
            <a href="/">Home</a>
            <a href="#about">About</a>
            <a href="#family-law">Family Law</a>
            <a href="#aal">Attorney Ad Litem</a>
            <a href="#contact">Contact</a>
          </nav>
          <FaBars
            color="#ffffff"
            size="1.5em"
            className="mobile-nav-toggle"
            onClick={() => {
              setShow(true)
            }}
          />
        </div>
      </header>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.nav className="mobile-nav" key={key} style={props}>
              <MobileNav
                close={() => {
                  setShow(false)
                }}
              />
              <FaTimesCircle
                className="mobile-nav-close"
                size="1.5em"
                color="#ffffff"
                onClick={() => {
                  setShow(false)
                }}
              />
            </animated.nav>
          )
      )}
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

import React from "react"
import "./mobileNav.scss"

function MobileNav({ close }) {
  return (
    <>
      <a href="/" onClick={close}>
        Home
      </a>
      <a href="#about" onClick={close}>
        About
      </a>
      <a href="#family-law" onClick={close}>
        Family Law
      </a>
      <a href="#aal" onClick={close}>
        Attorney Ad Litem
      </a>
      <a href="#contact" onClick={close}>
        Contact
      </a>
    </>
  )
}

export default MobileNav

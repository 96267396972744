import React from "react"
import "./footer.scss"

function Footer() {
  return (
    <footer>
      <p>
        © {new Date().getFullYear()} Cynthia Moody Attorney at Law <br />{" "}
        Designed by &nbsp;
        <a href="https://gridviewdesign.com">Gridview</a>
      </p>
    </footer>
  )
}

export default Footer
